import React, {useState, useEffect} from 'react'

import { 
  ButtonGroup, 
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
  IconButton,
} from "@material-tailwind/react";

export default function MintPAToken() {
  const [type, setType] =  useState(0);
  const [ticker, setTicker] = useState("");
  const [message, setMessage] = useState("tapwallet.io-");
  const [maxSupply, setMaxSupply] = useState(21000);
  const [limitPerMint, setLimitPerMint] = useState(1);
  const [inscriptionId, setInscriptionId] = useState("");
  const [mintCost, setMintCost] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [receiveAddress, setReceiveAddress] = useState("");
  const [amount, setAmount] = useState(1);

  const handleType = (value) => {
    setType(value);
  }

  const handleTicker = (value) => {
    setTicker(value);
    setMessage("tapwallet.io-" + value);
  }

  const handleMessage = (value) => {
    setMessage(value);
  }

  const handleSubmit = (value) => {

  }

  const handleMaxSupply = (value) => {
    setMaxSupply(value);
  }

  const handleInscriptionId = (value) => {
    setInscriptionId(value);
  }

  const handleLimitPerMint = (value) => {
    setLimitPerMint(value);
  }

  const handleMintCost = (value) => {
    setMintCost(value);
  }

  const handlePassword = (value) => {
    setPassword(value);
  }

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
  }

  const handlePaymentAddress = (value) => {
    setPaymentAddress(value);
  }
  
  const handleReceiveAddress = (value) => {
    setReceiveAddress(value);
  }

  const handleAmount = (value) => {
    setAmount(value);
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-start items-center mb-10 min-h-[600px]">
      <div className="flex flex-row gap-2 justify-center max-w-[500px] w-full sm:mt-[80px] mt-10">
        <button className = {`${type == 0 ? 'bg-primary' : 'bg-gray-700'} hover:bg-primary-hover text-[16px] text-white rounded-md px-6 py-2 font-bold`} onClick={() => handleType(0)}>Prv Auth</button>
        <button className = {`${type == 1 ? 'bg-primary' : 'bg-gray-700'} hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleType(1)}>Deploy</button>
        <button className = {`${type == 2 ? 'bg-primary' : 'bg-gray-700'} hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleType(2)}>Mint</button>
      </div>
      <div className="flex flex-row bg-gray-900 rounded-md max-w-[500px] w-full mt-2 px-5 py-10">
      {
        type == 0 ? 
        <div className="flex flex-col gap-6 w-full items-center">
          <div className="max-w-[300px] w-full">
            <Input label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { handleTicker(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="" type="text" color="black" value={message} onChange={(e) => { handleMessage(e.target.value)}} disabled/>
          </div>
          <button className = {`bg-primary hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleSubmit(0)}>CREATE</button>
        </div> 
        :
        <></>
      }
      {
        type == 1 ? 
        <div className="flex flex-col gap-6 w-full items-center">
          <div className="max-w-[300px] w-full">
            <Input label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { handleTicker(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Max Supply" type="number" color="white" value={maxSupply} onChange={(e) => { handleMaxSupply(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Limit Per Mint" type="number" color="white" value={limitPerMint} onChange={(e) => { handleLimitPerMint(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Prv Auth ID (inscription id)" type="text" color="white" value={inscriptionId} onChange={(e) => { handleInscriptionId(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Payment receiver address" type="text" color="white" value={paymentAddress} onChange={(e) => { handlePaymentAddress(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Mint Price (in sats)" type="number" color="white" value={mintCost} onChange={(e) => { handleMintCost(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Admin Password" type="password" color="white" value={password} onChange={(e) => { handlePassword(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Confirm Admin Password" type="password" color="white" value={confirmPassword} onChange={(e) => { handleConfirmPassword(e.target.value)}}/>
          </div>
          <button className = {`bg-primary hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleSubmit(0)}>DEPLOY</button>
        </div> 
        :
        <></>
      }
      {
        type == 2 ? 
        <div className="flex flex-col gap-6 w-full items-center">
          <div className="max-w-[300px] w-full">
            <Input label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { handleTicker(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Receiver Address" type="text" color="white" value={receiveAddress} onChange={(e) => { handleReceiveAddress(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full">
            <Input label="Amount" type="number" color="white" value={amount} onChange={(e) => { handleAmount(e.target.value)}}/>
          </div>
          <button className = {`bg-primary hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleSubmit(0)}>MINT</button>
        </div> 
        :
        <></>
      }
      </div>
    </div>
  )
}
