const stampUrl = "https://dev.bitcoinstamps.xyz";
const stampProdUrl = "https://bitcoinstamps.xyz";

export const getSrc20Balance = async (address, limit = 12, page = 1) => {

  const url = `${stampUrl}/api/v2/src20/balance/${address}?limit=${limit}&page=${page}`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getStampBalance = async (address, limit = 12, page = 1) => {

  const url = `${stampUrl}/api/v2/stamps/balance/${address}?limit=${limit}&page=${page}`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

  