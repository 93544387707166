import React from 'react'

export default function Test() {
  /*
  const handleRegister = async () => {
    if (domain == "") {
      toast.error("Please insert domain name!");
      return;
    }
    if (address == "") {
      toast.error("Please insert wallet address!");
      return;
    }
    let data = await checkDomain(domain);
    if (data.isExists)
    {
      toast.error("Domain already exists!");
      return;
    }

    if (wallet.nostrPaymentAddress == "")
    {
      toast.error("Please connect your wallet!");
      return;
    }

    setLoading(true);
    await lockDomain(domain);

    const { Address, Script, Signer, Tap, Tx } = window.tapscript;

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    console.log('PUBKEY', pubkey);

    let feeRateData = await getFeeRate();
    let feeRate = Math.round(feeRateData.Fast);

    console.log(feeRate);

    let total_fees = calculateFee(1,2,feeRate) + royalty * 2 // 1: input counts, 2: output counts
    let fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    await registerPayment(privkey, fundingAddress);
    try{
      if (wallet.domain == "tapwallet") {
        await window.tapwallet.sendBitcoin(fundingAddress, total_fees);
      }
      if (wallet.domain == "unisat") {
        await window.unisat.sendBitcoin(fundingAddress, total_fees);
      }
      if (wallet.domain == "okxwallet") {
        await window.okxwallet.bitcoin.sendBitcoin(fundingAddress,total_fees);
      }
      if (wallet.domain == "xverseWallet") {
        const sendBtcOptions = {
          payload: {
            network: {
              type: "Mainnet",
            },
            recipients: [
              {
                address: fundingAddress,
                amountSats: BigInt(total_fees),
              },
            ],
            senderAddress: wallet.nostrPaymentAddress,
          },
          onFinish: (response) => {
            //alert(response);
            console.log(response);
          },
          onCancel: () => alert("Payment rejected by user. Try again."),
        };

        await sendBtcTransaction(sendBtcOptions);
      }
      if (wallet.domain == "magiceden") {
        let res = await getPaymentUtxos(wallet.nostrPaymentAddress, fundingAddress, total_fees, wallet.paymentPublicKey, feerate);
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
                let res_data = await getPaymentTx(response.psbtBase64);
                console.log("--txHex--", res_data.hex);
                // send transaction
                await fetch('https://mempool.space/api/tx', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'text/plain'
                  },
                  body: res_data.hex
                })
            },
            onCancel: () => {
                alert('Request canceled');
            },
          });
  
        }
        else {
          alert("Insufficinet balance.");
          unlockDomain(domain);
          set
          setLoading(false);
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      unlockDomain(domain);
      setLoading(false);
      return;
    }

    await loopTilAddressReceivesMoney(fundingAddress, true);
    await waitSomeSeconds(2);
    let txinfo = await addressReceivedMoneyInThisTx(fundingAddress);

    await registerDomain(fundingAddress, domain, address);

    let txid = txinfo[0];
    let vout = txinfo[1];
    let amt = txinfo[2];

    let outputs = [];

    outputs.push(
      {
          value: royalty,
          scriptPubKey: [ 'OP_1', Address.p2tr.decode(tippingAddress, encodedAddressPrefix).hex ]
      }
    );

    outputs.push(
      {
          value: royalty,
          scriptPubKey: [ 'OP_1', Address.p2tr.decode(ownerAddress, encodedAddressPrefix).hex ]
      }
    );

    const init_redeemtx = Tx.create({
      vin  : [{
          txid: txid,
          vout: vout,
          prevout: {
              value: amt,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : outputs
    })

    const init_sig = await Signer.taproot.sign(seckey.raw, init_redeemtx, 0, {extension: init_leaf});
    init_redeemtx.vin[0].witness = [ init_sig.hex, init_script, init_cblock ];

    let rawtx = Tx.encode(init_redeemtx).hex;
    let _txid = await pushBTCpmt(rawtx);

    setLoading(false);
  }*/
  const handleClick = async () => {
    try {
      let accounts = await window.tapwallet.requestAccounts();
      console.log('connect success', accounts);
    } catch (e) {
      console.log('connect failed');
    }
  }
  return (
    <div className = "mt-[80px] text-white">
      <button onClick={handleClick}>Connect Wallet</button>
    </div>
  )
}
