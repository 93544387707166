import { marketTip, marketTipAddress, NUMBER_OF_DUMMY_UTXOS_TO_CREATE, DUMMY_UTXO_VALUE, MIN_UTXO_VALUE } from "../configs/constant";


const isSatsConnectCompatibleWallet = (wallet) => {
    return SatsConnectNamespace in wallet.features;
}

export const getDummyPsbt = async (data) => {

  const url = 'https://api.tapwallet.io/ordinal/getDummyPsbt';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let psbt = await response.text();
  return psbt;
}

export const getDummyPsbtNew = async (data) => {

  const url = 'https://api.tapwallet.io/ordinal/getDummyPsbtNew';

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let psbt = await response.text();
  return psbt;
}

export const buyOrdinals = async (data) => {

  const url = 'https://api.tapwallet.io/ordinal/buyOrdinals';

  if (data.dummyUtxos.length > 0) {
    let result = await getInscriptionData(data.inscriptionId);

    data.outputValue = parseInt(result.value);
  }
  else {
    data.outputValue = 0;
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let psbt = await response.text();

  return psbt;
}

const getSignedPsbt = async (signedPsbt) => {
  const url = 'https://api.tapwallet.io/ordinal/getSignedPsbt';
  const data = {
    signedPsbt: signedPsbt
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  response = await response.text();

  return response;
}

const getAddressUtxos =  async(address) => {
  let response = await fetch(`https://mempool.space/api/address/${address}/utxo`);
  return await response.json();
}

const getRunesUtxos = async(address) => {
  const url = `https://api-mainnet.magiceden.dev/v2/ord/btc/runes/utxos/wallet/${address}`;
  const headers = new Headers();
  headers.append("Bearer", "7a1d7321-a84a-4246-964b-4bed2b9be751");
  try {
    const response = await fetch(url, {
      method: 'GET', 
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data.list;
  }
  catch(e) {
    console.error("Fetch error:", e.message);
  }
}

const getUnisatOrdinals = async(address) => {
  const url = 'https://wallet-api.unisat.io/v5/ordinals/inscriptions';
  const params = new URLSearchParams({
    address: address,
    cursor: 0,
    size: 100
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data.list;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getBtcUtxos = async(address) => {
  const url = 'https://wallet-api.unisat.io/v5/address/btc-utxo';
  const params = new URLSearchParams({
    address: address,
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

const filterInscriptionUtxos = async (utxos, inscriptionUtxos) => {
  const newUtxos = [];
  for(let i=0;i<utxos.length;i++){   
    let result = await doesUtxoContainInscription(utxos[i], inscriptionUtxos);
    if (result == false) newUtxos.push(utxos[i]);
  }

  newUtxos.sort((a, b) => b.value -a.value);
  return newUtxos;
}

const doesUtxoContainInscription = async(utxo, inscriptionUtxos) => {
  for (const inscriptionUtxo of inscriptionUtxos) {
    if ( inscriptionUtxo.output == utxo.txid + ":" + utxo.vout) return true;
  }
  return false;
}

export const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 1 ) => {
  if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
    throw new Error("Invalid fee rate.");

  const baseTxSize = 10;
  const inSize = 57.5;
  const outSize = 43;
  const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
  const fee = Math.round(txSize * recommendedFeeRate);
  return fee;
}

export const getFeeRateUpdated = async () => {
  const recommendedFeeRate = await fetch(`https://mempool.space/api/v1/fees/recommended`)
           .then(response => response.json())
           .then(data => data['fastestFee']) 
  return Math.round(recommendedFeeRate * 1.3);
}

export const getConfirmedUtxos = (utxos) => {
  const newUtxos = [];
  for (const utxo of utxos) {
    if (utxo.status != undefined && utxo.status.confirmed != undefined && utxo.status.confirmed == true) {
      newUtxos.push(utxo);
    }
  }
  return newUtxos;
}

export const getUtxos = async (address) => {
  let utxos = await getAddressUtxos(address);
  let inscriptionUtxos = await getUnisatOrdinals(address);
  utxos = await filterInscriptionUtxos(utxos, inscriptionUtxos);

  let dummyUtxos = [];
  let selectedUtxos = [];

  for(const uxo of utxos) {
    if (uxo.value == DUMMY_UTXO_VALUE)
    {
      dummyUtxos.push(uxo);
    }
  }
  return dummyUtxos;
}

const getInscriptionData = async (inscriptionId) => {
  const url = `https://api.hiro.so/ordinals/v1/inscriptions/${inscriptionId}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}


