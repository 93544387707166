import React from 'react'
import {useState} from 'react'
import DmtMarketplace from '../components/Marketplace/DmtMarketplace';
import TapMarketplace from '../components/Marketplace/TapMarketplace';
import UnatMarketplace from '../components/Marketplace/UnatMarketplace';
import NameMarketplace from '../components/Marketplace/NameMarketplace';
import ActivityMarketplace from '../components/Marketplace/ActivityMarketplace';

export default function Marketplace() {
  const [tab, setTab] = useState(0);
  const [type, setType]= useState(3);
  const [ticker, setTicker] = useState("");

  const handleActivity = (type, ticker) => {
    setTab(3);
    setType(type);
    setTicker(ticker);
  }

  return (
    <div className="text-white mt-[65px] w-full md:px-7 px-2 flex flex-col items-center mb-10 min-h-[600px]">
      <div className="flex flex-row gap-2 w-full mt-5">
        <div className={`sm:text-[20px] text-[16px] ${tab == 0 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary sm:px-4 px-2 py-1 rounded-md cursor-pointer`} onClick={() => setTab(0)}>TAP</div>
        <div className={`sm:text-[20px] text-[16px] ${tab == 1 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary sm:px-4 px-2 py-1 rounded-md cursor-pointer`} onClick={() => setTab(1)}>DMT</div>
        <div className={`sm:text-[20px] text-[16px] ${tab == 2 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary sm:px-4 px-2 py-1 rounded-md cursor-pointer`} onClick={() => setTab(2)}>UNAT</div>
        <div className={`sm:text-[20px] text-[16px] ${tab == 4 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary sm:px-4 px-2 py-1 rounded-md cursor-pointer`} onClick={() => setTab(4)}>NAMES</div>
        <div className={`sm:text-[20px] text-[16px] px-2 py-1 bg-primary hover:bg-primary-hover cursor-pointer text-white rounded-md`} onClick={() => {setTab(3); setTicker(""); setType(3)}}>ACTIVITY</div>
      </div>
      {
        tab == 0 ? <TapMarketplace handleActivity = { handleActivity } />: <></>
      }
      {
        tab == 1 ? <DmtMarketplace handleActivity = { handleActivity }  /> : <></>
      }
      {
        tab == 2 ? <UnatMarketplace handleActivity = { handleActivity } /> : <></>
      }
      {
        tab == 4 ? <NameMarketplace handleActivity = { handleActivity } /> : <></>
      }
      {
        tab == 3 ? <ActivityMarketplace type={type} ticker={ticker}/> : <></>
      }
    </div>
  )
}
