import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { amountFormat } from '../../util/format-data';
import { getMarketplaceData, saveMarketplaceData, getMarketplacePsbt, getSignedPsbt } from '../../util/api';
import { marketplaceFeeLimit, marketplaceFeeRate } from '../../configs/constant';
import { getPaymentTx } from '../../util/api';
import { savePointData } from '../../util/api';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner
} from "@material-tailwind/react";
import toast, { Toaster } from 'react-hot-toast';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';

import { useWallet, useWallets } from '@wallet-standard/react';
import { latestTokenNew } from '../../util/new-api';

export default function TapCard(props) {
  const wallet = useSelector(state => state.wallet);

  const { wallets } = useWallets();
  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const {data, price, type } = props;
  const [sats, setSats] = useState(0);
  const [id, setId] = useState(0);
  const [mode, setMode] = useState(true);
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [operationMode, setOperationMode] = useState(0);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    let param = {
      ownerAddress: wallet.nostrOrdinalsAddress,
      inscriptionId: data.id,
      deleteFlag: 0
    }
    let results = await getMarketplaceData(param);
    if (results.total > 0) {
      setMode(false);
      setSats(results.data[0].price);
      setId(results.data[0].id);
      setAmount(results.data[0].price);
    }
    else {
      setMode(true);
      setSats(0);
      setAmount(0);
    }
  }

  const handleAmount = (e) => {
    let amount = e.target.value;
    setAmount(amount);
  }

  const showSale = (value) => {
    setShow(true);
    setOperationMode(value);
  }

  const handleSale = async () => {
    let totalPrice = parseInt(data.amount) * amount;
    if (totalPrice < marketplaceFeeLimit) {
      setShow(false);
      toast.error("Total price should be grater than 0.000005 btc!");
      return;
    }
    setLoading(true);

    let responses = await latestTokenNew(wallet.nostrOrdinalsAddress);
    let fee = 0;
    if (responses.total == 0) {
      fee = Math.floor(totalPrice * marketplaceFeeRate);
      if (fee < marketplaceFeeLimit) fee = marketplaceFeeLimit;
    }

    let targetPrice = totalPrice - fee;
    let params = {
      inscriptionId: data.id,
      ordinalAddress: wallet.nostrOrdinalsAddress,
      price: Math.floor(targetPrice),
      provider: wallet.domain,
      pubkey: wallet.ordinalsPublicKey,
      paymentAddress: wallet.nostrPaymentAddress
    }

    let response = await getMarketplacePsbt(params);

    try{
      let signedPsbt;
      let signedPsbtNew;
      if (wallet.domain == "unisat") {
        signedPsbt = await window.unisat.signPsbt(response);
      }
      else if (wallet.domain == "tapwallet") {
        signedPsbt = await window.tapwallet.signPsbt(response);
      }
      else if (wallet.domain == "okxwallet") {
        signedPsbt = await window.okxwallet.bitcoin.signPsbt(response);
      }
      else if (wallet.domain == "magiceden") {
        let signIndexes = [];
        for(let i=0;i<1; i++){
          signIndexes.push(i);
        }
  
        let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

        await signTransaction({
          getProvider: async () =>
            magicedenWallets[0].features['sats-connect:'].provider,
          payload: {
              network: {
                  type: BitcoinNetworkType.Mainnet,
              },
              psbtBase64: response,
              broadcast: false,
              message: "Sign Transaction",
              inputsToSign: [
                  {
                      address: wallet.nostrOrdinalsAddress,
                      signingIndexes: signIndexes,
                      sigHash: 131
                  },
              ],
          },
          onFinish: async (response) => {  
            if (operationMode == 1) {
              params = {
                id: id,
                price: amount,
                psbt: response.psbtBase64,
                reason: "edit",
                totalPrice: totalPrice,
                actualPrice: Math.floor(targetPrice)
              }
            }
            else {
              params = {
                ownerAddress : wallet.nostrOrdinalsAddress,
                inscriptionId : data.id,
                ticker: data.ticker,
                type: type, // tap
                amount: parseInt(data.amount),
                price: amount,
                psbt: response.psbtBase64,
                reason: "sale",
                actualPrice: Math.floor(targetPrice),
                totalPrice: totalPrice,
                number: data.number
              }
            }
            await saveMarketplaceData(params);
            await fetchData();

            if (operationMode != 1) {
              params = {
                address : wallet.nostrOrdinalsAddress,
                point : 1,
                reason : "List Tap/Dmt",
                deleteFlag : 0
              }
              await savePointData(params);
            }
            
            setShow(false);
            setLoading(false);
            toast.success("Successfully listed on marketplace!");
            return;
          },
          onCancel: () => {
            toast.error("User reject request!")
            setLoading(false);
            setShow(false);
            return;
          },
        });
        return;
      }
      else if (wallet.domain == "xverseWallet") {
        let signIndexes = [];
        for(let i=0;i<1; i++){
          signIndexes.push(i);
        }
  
        await signTransaction({
          payload: {
              network: {
                  type: BitcoinNetworkType.Mainnet,
              },
              psbtBase64: response,
              broadcast: false,
              message: "tip the author! Don't worry this will not be broadcasted.",
              inputsToSign: [
                  {
                      address: wallet.nostrOrdinalsAddress,
                      signingIndexes: signIndexes,
                      sigHash: 131
                  },
              ],
          },
          onFinish: async (response) => {  
            if (operationMode == 1) {
              params = {
                id: id,
                price: amount,
                psbt: response.psbtBase64,
                reason: "edit",
                totalPrice: totalPrice,
                actualPrice: Math.floor(targetPrice)
              }
            }
            else {
              params = {
                ownerAddress : wallet.nostrOrdinalsAddress,
                inscriptionId : data.id,
                ticker: data.ticker,
                type: type, // tap
                amount: parseInt(data.amount),
                price: amount,
                psbt: response.psbtBase64,
                reason: "sale",
                actualPrice: Math.floor(targetPrice),
                totalPrice: totalPrice,
                number: data.number
              }
            }
            await saveMarketplaceData(params);
            await fetchData();

            if (operationMode != 1) {
              params = {
                address : wallet.nostrOrdinalsAddress,
                point : 1,
                reason : "List Tap/Dmt",
                deleteFlag : 0
              }
              await savePointData(params);
            }

            setShow(false);
            setLoading(false);
            toast.success("Successfully listed on marketplace!");
            return;
          },
          onCancel: () => {
            toast.error("User reject request!")
            setLoading(false);
            setShow(false);
            return;
          },
        });
        return;
      }
      else {
        toast.error("This wallet does not support yet. Please wait!");
        setLoading(false);
        setShow(false);
        return;
      }
      signedPsbtNew = await getSignedPsbt(signedPsbt);

      if (operationMode == 1) {
        params = {
          id: id,
          price: amount,
          psbt: signedPsbtNew,
          reason: "edit",
          totalPrice: totalPrice,
          actualPrice: Math.floor(targetPrice)
        }
      }
      else {
        params = {
          ownerAddress : wallet.nostrOrdinalsAddress,
          inscriptionId : data.id,
          ticker: data.ticker,
          type: type, // tap
          amount: parseInt(data.amount),
          price: amount,
          psbt: signedPsbtNew,
          reason: "sale",
          actualPrice: Math.floor(targetPrice),
          totalPrice: totalPrice,
          number: data.number
        }
      }
      
      await saveMarketplaceData(params);
      await fetchData();

      if (operationMode != 1) {
        params = {
          address : wallet.nostrOrdinalsAddress,
          point : 1,
          reason : "List Tap/Dmt",
          deleteFlag : 0
        }
        await savePointData(params);
      }

      setShow(false);
      setLoading(false);
      toast.success("Your item(s) have been listed!");
    }
    catch(e)
    {
      console.log(e);
      setLoading(false);
      setShow(false);
    }
  }

  const handleDelist = async () => {
    setLoading(true);
    let params = {
      inscriptionId: data.id,
      ordinalAddress: wallet.nostrOrdinalsAddress,
      price: 10000 * Math.pow(10, 8),
      provider: wallet.domain,
      pubkey: wallet.ordinalsPublicKey,
      paymentAddress: wallet.nostrPaymentAddress
    }

    let response = await getMarketplacePsbt(params);

    try{
      let signedPsbt;
      let signedPsbtNew;
      if (wallet.domain == "unisat") {
        signedPsbt = await window.unisat.signPsbt(response);
      }
      else if (wallet.domain == "tapwallet") {
        signedPsbt = await window.tapwallet.signPsbt(response);
      }
      else if (wallet.domain == "okxwallet") {
        signedPsbt = await window.okxwallet.bitcoin.signPsbt(response);
      }
      else if (wallet.domain == "magiceden") {
        let signIndexes = [];
        for(let i=0;i<1; i++){
          signIndexes.push(i);
        }
  
        let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

        await signTransaction({
          getProvider: async () =>
            magicedenWallets[0].features['sats-connect:'].provider,
          payload: {
              network: {
                  type: BitcoinNetworkType.Mainnet,
              },
              psbtBase64: response,
              broadcast: false,
              message: "Sign Transaction",
              inputsToSign: [
                  {
                      address: wallet.nostrOrdinalsAddress,
                      signingIndexes: signIndexes,
                      sigHash: 131
                  },
              ],
          },
          onFinish: async (response) => {  
            let params = {
              id: id,
              reason: 'delist',
              psbt: signedPsbtNew
            }

            await saveMarketplaceData(params);
            await fetchData();

            params = {
              address : wallet.nostrOrdinalsAddress,
              point : -1,
              reason : "Delist Tap/Dmt",
              deleteFlag : 0
            }
            await savePointData(params);

            setShow(false);
            setLoading(false);
            toast.success("Your item(s) have been delisted.");
            return;
          },
          onCancel: () => {
            toast.error("User reject request!")
            setLoading(false);
            setShow(false);
            return;
          },
        });
        return;
      }
      else if (wallet.domain == "xverseWallet") {
        let signIndexes = [];
        for(let i=0;i<1; i++){
          signIndexes.push(i);
        }
  
        await signTransaction({
          payload: {
              network: {
                  type: BitcoinNetworkType.Mainnet,
              },
              psbtBase64: response,
              broadcast: false,
              message: "tip the author! Don't worry this will not be broadcasted.",
              inputsToSign: [
                  {
                      address: wallet.nostrOrdinalsAddress,
                      signingIndexes: signIndexes,
                      sigHash: 131
                  },
              ],
          },
          onFinish: async (response) => {  
            let params = {
              id: id,
              reason: 'delist',
              psbt: signedPsbtNew
            }

            await saveMarketplaceData(params);
            await fetchData();

            params = {
              address : wallet.nostrOrdinalsAddress,
              point : -1,
              reason : "Delist Tap/Dmt",
              deleteFlag : 0
            }
            await savePointData(params);

            setShow(false);
            setLoading(false);
            toast.success("Your item(s) have been delisted.");
            return;
          },
          onCancel: () => {
            toast.error("User reject request!")
            setLoading(false);
            setShow(false);
            return;
          },
        });
        return;
      }
      else {
        toast.error("This wallet does not support yet. Please wait!");
        setLoading(false);
        setShow(false);
        return;
      }
      signedPsbtNew = await getSignedPsbt(signedPsbt);

      let params = {
        id: id,
        reason: 'delist',
        psbt: signedPsbtNew
      }
      
      await saveMarketplaceData(params);
      await fetchData();

      params = {
        address : wallet.nostrOrdinalsAddress,
        point : -1,
        reason : "Delist Tap/Dmt",
        deleteFlag : 0
      }
      await savePointData(params);

      setShow(false);
      setLoading(false);
      toast.success("Your item(s) have been delisted.");
    }
    catch(e)
    {
      console.log(e);
      setLoading(false);
      setShow(false);
    }
  }

  return (
    <div className="w-full rounded-md border-borderColor border-[1px] border-solid bg-gray-900 max-w-[320px] m-auto">
      <div className="flex flex-row justify-between p-1">
        <div className="font-bold pl-1">{data.ticker.toUpperCase()}</div>
        <div className="font-semibold text-blue-600">Transfer</div>
      </div>
      <div className="flex flex-row justify-center text-[24px] font-bold">
        {data.amount}
      </div>
      <div className="flex flex-row justify-center items-center mt-3 gap-2">
        <span className="font-bold text-primary text-[24px]">{amountFormat(sats)}</span>
        <span className="text-gray-400">sats/1</span>
      </div>
      <div className="flex flex-row justify-center">
        ${Math.round(sats / Math.pow(10, 6) * price) / 100}
      </div>
      <div className="bg-[#121212] mt-2 pb-3 px-4 rounded-b-md">
        <div className=" w-full border-b-[1px] border-b-solid border-b-gray-900">#{data.number}</div>
        <div className="flex flex-row justify-between mt-1">
          <div className="flex flex-row gap-1 items-center">
            <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
            <span className="text-primary">{parseInt(data.amount) * sats / Math.pow(10, 8) }</span>
          </div>
          <div className="text-gray-400">${Math.round(parseInt(data.amount) * sats / Math.pow(10, 6) * price)/100}</div>
        </div>
        {
          mode ? 
            <div className="w-full text-center border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold flex flex-row items-center justify-center mt-4 mb-2 p-1 cursor-pointer hover:text-primary hover:border-primary" onClick={() => showSale(0)}>List</div> : 
            <div className="w-full gap-2 flex flex-row">
              <div className="w-full text-center border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold flex flex-row items-center justify-center mt-4 mb-2 p-1 cursor-pointer hover:text-primary hover:border-primary" onClick={() => showSale(1)}>Edit</div>
              <div className="w-full text-center border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold flex flex-row items-center justify-center mt-4 mb-2 p-1 cursor-pointer hover:text-primary hover:border-primary" onClick={() => showSale(2)}>Delist</div>
            </div>
        }
      </div>
      <Dialog
        open={show}
        size={"xs"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogHeader>
          {
            operationMode == 0 ?
              <div className="flex flex-row w-full justify-center mt-5 text-[32px] font-bold text-primary">LIST</div> : <></>
          }
          {
            operationMode == 1 ?
              <div className="flex flex-row w-full justify-center mt-5 text-[32px] font-bold text-primary">EDIT</div> : <></>
          }
          {
            operationMode == 2 ?
              <div className="flex flex-row w-full justify-center mt-5 text-[32px] font-bold text-primary">DELIST</div> : <></>
          }
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-2 md:px-10 px-2">
            <div className="flex flex-row gap-1 w-full justify-center text-white">
              <span className="text-[24px] font-bold">{data.amount}</span>
              <span className="text-[24px] font-bold text-blue-800">${data.ticker.toUpperCase()}</span>
            </div>
            {
              operationMode != 2 ?
                <div className="flex flex-col gap-2">
                  <div className="w-full font-bold text-white text-[28px] text-center">
                    ${Math.floor(parseInt(data.amount) * amount * 10 * price / Math.pow(10, 8)) / 10}
                  </div>
                  <div className="w-full max-w-[300px] grid grid-cols-2 m-auto">
                    <div className="w-full font-bold text-white text-[20px] text-left">
                      <div>Price:</div>
                      <div>Price per unit:</div>
                    </div>
                    <div className="w-full font-bold text-white text-[20px] text-right mb-2">
                      <div>{amount * data.amount / Math.pow(10, 8)} BTC</div>
                      <div>{amount / Math.pow(10, 8)} BTC</div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-1 justify-center">
                    <div className="w-[250px]">
                      <Input color="white" label = "Price per unit" value = {amount} onChange = {(e) => {handleAmount(e)}}/>
                    </div>
                    <span className="font-bold text-white ml-1">sats</span>
                  </div>
                </div> : <></>
            }
          </div>
        </DialogBody>
        <DialogFooter>
          {
            loading ? 
            <div className="flex flex-row justify-center w-full">
              <Spinner className="h-16 w-16 text-gray-900/50" color="pink"/>
            </div>
            :
            <div className="flex flex-row w-full justify-center gap-8">
              {
                operationMode == 2 ? 
                  <button className="bg-primary hover:bg-primary-hover text-[14px] text-white rounded-md px-12 font-bold" onClick={() => handleDelist()}>DELIST</button>
                  :
                  <button className="bg-primary hover:bg-primary-hover text-[14px] text-white rounded-md px-12 font-bold" onClick={() => handleSale()}>LIST</button>
              }
              <Button
                onClick={() => setShow(false)}
                className="py-[12px] px-[32px]"
              >
                <span>Return</span>
              </Button>
            </div>
          }
        </DialogFooter>
      </Dialog>
    </div>
  )
}
