import React, {useState, useEffect, useCallback} from 'react'
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress
} from '@material-tailwind/react';
import { v4 as uuidv4 } from 'uuid';
const { io } = require('socket.io-client');
import TapIndexer from '../components/Indexer/TapIndexer';
import DmtIndexer from '../components/Indexer/DmtIndexer';
import UnatIndexer from '../components/Indexer/UnatIndexer';

export default function IndexPage() {
  const [tab, setTab] = useState(0);

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col items-center mb-10 min-h-[600px]">
      <div className="flex flex-row gap-8 w-full mt-5">
        <div className={`text-[20px] ${tab == 0 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(0)}>TAP</div>
        <div className={`text-[20px] ${tab == 1 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(1)}>DMT</div>
        <div className={`text-[20px] ${tab == 2 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(2)}>UNAT</div>
      </div>
      {
        tab == 0 ? <TapIndexer />: <></>
      }
      {
        tab == 1 ? <DmtIndexer /> : <></>
      }
      {
        tab == 2 ? <UnatIndexer /> : <></>
      }
    </div>
  )
}
