import React, {useState, useEffect, useCallback} from 'react'
import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { registerPaymentRandom, latestTokenRandom, checkWhiteListedAddress, registerTokenRandom, getPaymentTx, getPaymentUtxos, getPaymentHistoryNew, savePaymentHistoryNew, getSignMintTextRandom, getMintStatus, checkWhiteListedDomain } from '../util/new-api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, getData, isValidTaprootAddress} from '../util/inscribe-util';
import FeeRateCard from '../components/FeeRateCard';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';


import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';
import { savePointData } from '../util/api';

export default function RecoverStamps() {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [feeValues, setFeeValues] = useState({
    "inscriptionFee": 0,
    "networkFee": 0,
    "serviceFee": 0,
    "royaltyFee": 0,
    "totalFee": 0
  });
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const [amount, setAmount] = useState(1);
  const [maximum, setMaximum] = useState(21000);
  const [sum, setSum] = useState(0);
  const [deployLimit, setDeployLimit] = useState(21000);
  const [address, setAddress] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [inscriptionStatus, setInscriptionStatus] = useState(false);

  const reservedAmount = 18500;

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        if (feeRateMode == "Custom") {
          setFeerate(customFee);
          setFeeValues(calculateFeeReal(customFee, amount));
        }
        else
        {
          let response = await getFeeRate();
          setFeeRates(response);
          setFeerate(response[feeRateMode]);
          setFeeValues(calculateFeeReal(response[feeRateMode], amount));
          if (customFee == 0) 
          {
            setCustomFee(response["Fast"] + 1);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [feeRateMode, amount, customFee])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  useEffect(() => {
    updateTokens(key, page);
  }, [page])

  useEffect(() => { 
    setAddress(wallet.nostrOrdinalsAddress);
  }, [wallet.nostrOrdinalsAddress]);

  const updateTokens = async (value, offset) => {
    setLoading(true);
    let data = await latestTokenRandom(value, 3, offset, limit);

    if (data.sum == null)
      setSum(0);
    else
      setSum(data.sum);

    setTokens(data.data);
    setTotal(Math.ceil(data.total / limit))
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const debouncedSearch = useCallback(debounce((value) => {
    setPage(1);
    updateTokens(value, 1)
  }, 300), []); 

  const handleSearch = (value) => {
    setKey(value);
    debouncedSearch(value);
  };

  const handleMint = async () => {
    setLoading(true);
    let mintStatus = await getMintStatus();

    // let addressMintCount = await checkWhiteListedAddress(address);
    // let whiteListedDomain = await checkWhiteListedDomain(address);

    // if (!addressMintCount.status && !whiteListedDomain.status) {
    //   toast.error("This address is not WL. Please wait for the next mint phase.");
    //   setLoading(false);
    //   return;
    // }

    if (mintStatus.status != 'start') {
      toast.error("Mint can not start!");
      setLoading(false);
      return;
    }

    if (amount != parseInt(amount) || parseInt(amount) < 1) {
      toast.error("Please insert valid amount!");
      setLoading(false);
      return;
    }

    if (amount > maximum) {
      toast.error("Amount can not exceed 1000!");
      setLoading(false);
      return;
    }

    if (amount > deployLimit - sum - reservedAmount) {
      toast.error("Amount can not maximum amount!");
      setLoading(false);
      return;
    }

    if (wallet.nostrOrdinalsAddress == "")
    {
      toast.error("Please connect your wallet!");
      setLoading(false);
      return;
    }

    if (address == "") {
      toast.error("Please insert inscription address!");
      setLoading(false);
      return;
    }

    if (!isValidTaprootAddress(address)) {
      toast.error("Please insert valid taproot address!");
      setLoading(false);
      return;
    }

    setLoading(false);
    setShow(true);
    setInscriptionStatus(false);
    
    const results = await getPaymentHistoryNew();
    const toggleValue = results[0].value;
    let fundingAddress = '';

    if (toggleValue == 0)
      fundingAddress = tippingAddress;
    else
      fundingAddress = ownerAddress;

    let isSuccess = true;

    let inscriptionFee = feeValues["inscriptionFee"] + feeValues["networkFee"];
    let fundingAmount = 0;

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";
    let salts = "";

    for(let i = 0; i< amount; i++)
    {
      let text = await getSignMintTextRandom(address, 1, 3);
      let textJson = JSON.parse(text);
      salts = salts + "," + textJson.prv.salt;
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    recipientList.push({
      address: fundingAddress,
      amountSats: BigInt(fundingAmount),
    })

    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // get payment utxos

    console.log(recipientList);

    const paymentUtxos = await getPaymentUtxos(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, fundingAmount, wallet.paymentPublicKey, feerate, amount, wallet.provider)

    if (paymentUtxos.status == "fail") {
      alert("Insufficinet balance.");
      setLoading(false);
      setShow(false);
      isSuccess = false;
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
                // let res_data = await getPaymentTx(response.psbtBase64);
                // console.log("--txHex--", res_data.hex);
                // // send transaction
                // await fetch('https://mempool.space/api/tx', {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'text/plain'
                //   },
                //   body: res_data.hex
                // })
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
            },
          });
        }
        else {
          alert("Insufficinet balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
                // let res_data = await getPaymentTx(response.psbtBase64);
                // console.log("--txHex--", res_data.hex);
                // // send transaction
                // await fetch('https://mempool.space/api/tx', {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'text/plain'
                //   },
                //   body: res_data.hex
                // })
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
            },
          });
  
        }
        else {
          alert("Insufficinet balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      return;
    }
    
    if (isSuccess) {
          // save private key and public key
      await registerPaymentRandom(privkey, address, 3, salts);

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      await registerTokenRandom(address, transactionData[0].txid + ":" + 0, 3, inscriptions.length);
      await waitSomeSeconds(1);

      for (let i = 0; i < inscriptions.length; i++) {
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
        await waitSomeSeconds(2);
      }
    }
    await savePaymentHistoryNew(1 - toggleValue);

    // let params = {
    //   address : address,
    //   point : 10 * inscriptions.length,
    //   reason : "Mint Tapzero",
    //   deleteFlag : 0
    // }
    // await savePointData(params);

    updateTokens(key, page);
    setLoading(false);
    setInscriptionStatus(true);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey) => {
    let _toAddress;
    let _script;
    let toAddress = address;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        include_mempool = false;
        inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {

        JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
    }
    
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const calculateFeeReal = (feeRate, _mintCount, dataSize = contentLength, ) => {
    let mintCount = parseInt(_mintCount);
    if (mintCount <= 0 || mintCount == NaN) mintCount = 1;
    let base_size = 160;
    let prefix = 160;
    let txsize = prefix + Math.floor(dataSize / 4);
    let inscriptionLength = mintCount;
    let inscriptionFee = padding ;
    let networkFee = Math.floor(feeRate * txsize * 1.1);
  
    const royalty = 20000;

    let royaltyFee = royalty * mintCount;
    let serviceFee = Math.floor((royaltyFee + inscriptionFee * mintCount + networkFee * mintCount) * serviceFeeRate);
    let totalFee = inscriptionFee * mintCount + networkFee * mintCount;
    return {
      "inscriptionFee": inscriptionFee,
      "networkFee": networkFee,
      "serviceFee": serviceFee,
      "royaltyFee": royaltyFee,
      "totalFee": totalFee
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      }
      <div className="text-white font-semibold text-[30px] mt-10 text-center bg-primary py-1 px-4 rounded-md">$iSTAMPS</div>
      <div className="text-primary font-semibold text-[24px] text-center mt-3">SEED ROUND FOR STAMPS CHAIN ON BITCOIN</div>
      <div className="text-white font-semibold text-[18px] text-center mt-2">INDEX | WALLET | EXPLORER | MARKETPLACE</div>
      <div className="text-white font-semibold text-[18px] text-center mt-2">50% OF #STAMPS MARKETPLACE FEES</div>
      <div className="text-white font-semibold text-[18px] text-center mt-2">DISTRIBUTED TO $iSTAMPS HOLDERS</div>
      <div className="md:min-w-[600px] min-w-[300px] border-gray-800 border-[1px] border-solid p-8 rounded-lg mt-5 flex flex-col gap-8 bg-[#081817] md:w-[600px] w-full">
        <div>
          <Input type="text" color="white" label = "Insert Amount Here" className="w-full md:min-w-[500px] min-w-[250px]" value={amount} onChange = {(e) => {setAmount(e.target.value)}}/>
        </div>
        <div>
          <Input type="text" color="white" label = "Inscription Receiver Address" className="w-full md:min-w-[500px] min-w-[250px]" value={address} onChange = {(e) => {setAddress(e.target.value)}}/>
        </div>
        <div>
          Select the network fee you want to pay:
        </div>
        <div className="grid grid-cols-3 gap-3">
          <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
          <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
          <FeeRateCard header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
        </div>
        {
          feeRateMode == "Custom" ? 
          <div>
             <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
          </div>
          : 
          <></>
        }
        <div className="m-auto md:w-[400px] w-[300px]">
          <div className="flex flex-row justify-between mt-1 text-[20px]">
            <div className="font-bold">Total Cost</div>
            <div className="text-right font-bold text-blue-600">{feeValues["totalFee"] / Math.pow(10, 8)} BTC</div>
          </div> 
          {/* <div className="grid grid-cols-2 mb-1">
            <div>InscriptionFee</div>
            <div className="text-right">{feeValues["inscriptionFee"] * amount} sats</div>
          </div>
          <div className="grid grid-cols-2 mb-1">
            <div>NetworkFee</div>
            <div className="text-right">{feeValues["networkFee"] * amount} sats</div>
          </div>
          <div className="grid grid-cols-2 mb-1">
            <div>ServiceFee</div>
            <div className="text-right">{feeValues["serviceFee"]} sats</div>
          </div>
          <div className="grid grid-cols-2 mb-1">
            <div>RoyaltyFee</div>
            <div className="text-right">{feeValues["royaltyFee"]} sats</div>
          </div>
          <div className="border-solid border-b-[1px] border-white"></div>
          <div className="grid grid-cols-2 mt-1">
            <div className="font-bold">TotalFee</div>
            <div className="text-right font-bold">{feeValues["totalFee"]} sats</div>
          </div> */}
        </div>
        <div className="flex flex-row w-full justify-center">
          <button className="bg-primary hover:bg-primary-hover rounded-md py-2 min-w-[240px]" onClick={handleMint}>Mint</button>
        </div>
      </div>
      <div className="md:min-w-[600px] min-w-[300px] flex flex-col mt-10 mb-10 md:w-[600px] w-full">
        <div className="w-full flex flex-row justify-between border-b-[1px] border-b-[#24242a] pb-1 mb-3">
          <div className="font-bold text-xl text-right">
            Completed: <span className="font-bold text-2xl text-primary">{sum}</span>
          </div>
          <div className="font-bold text-xl text-right">
            Reserved: <span className="font-bold text-2xl text-primary">{reservedAmount}</span>
          </div>
          <div className="font-bold text-xl text-right">
            Available: <span className="font-bold text-2xl text-primary">{deployLimit - sum - reservedAmount}</span>
          </div>
        </div>
        <Input type="text" color="white" label = "Search by address" className="w-full md:min-w-[500px] min-w-[250px]" value = {key}
                    onChange = {(e) => {handleSearch(e.target.value)}}/>
        <div className="w-full border-gray-800 border-[1px] border-solid rounded-lg mt-4 bg-[#081817]">
          <div className="w-full grid grid-cols-2 py-4 px-6 bg-[#1e1f1f]">
            <div>Address</div>
            <div>Amount</div>
          </div>
          {
            tokens.length == 0 ? <div className="w-full flex flex-row py-4 px-6 text-gray-500 hover:bg-[#121313] justify-center">No Mint Tokens</div> : 
            <>
              {
                tokens.map((data) => {
                  return (
                    <div className="w-full grid grid-cols-2 py-4 px-6 text-gray-500 hover:bg-[#121313] cursor-pointer" key={uuidv4()}>
                      <div onClick={() => handleCopy(data.address, 2)}>{formatAddress(data.address)}</div>
                      <div>{data.amount}</div>
                    </div>
                  )
                })
              }
            </>
          }
        </div>
        {
          total > 1 ? 
          <div className="flex flex-row items-center gap-8 justify-end mt-5">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="white" className="font-normal">
              Page <strong className="text-primary">{page}</strong> of{" "}
              <strong className="text-primary">{total}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          :
          <></>
        }
      </div>
      <Dialog
        open={show}
        size={"xs"}
      >
        <DialogHeader>
          {
            inscriptionStatus ? 
              <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 text-[32px] font-bold text-primary">
                <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> <span>Mint successful.</span>
              </div>
              :
              <div className="flex flex-row w-full justify-center mt-6 text-[32px] font-bold text-primary">
                Inscribing Now
              </div>
          }
        </DialogHeader>
        <DialogBody>
          <div className={`flex flex-col gap-3 w-full ${inscriptionStatus ? 'min-h-[100px]' : 'min-h-[160px]'}`}>
            {
              inscriptionStatus ? 
              <div className="flex flex-col items-center gap-4">
                {/* <div className="flex flex-row justify-center w-full text-[20px] px-2 font-semibold text-green-600 text-center">
                  <CheckCircleIcon strokeWidth={2} className="h-24 w-24" />
                </div> */}
                <div className="w-[180px] mt-5">
                  <Button
                    onClick={() => {
                      setShow(false);
                      setInscriptionStatus(false);
                    }}
                    fullWidth
                  >Close</Button>
                </div>
              </div>
              :
              <div className="flex flex-col items-center gap-5">
                <div className="flex flex-row justify-center w-full font-semibold text-[#616161] text-[20px] px-6 text-center">
                  Don´t close this window before the transaction is complete.
                </div>
                <Spinner className="h-12 w-12" />
              </div>
            }
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
