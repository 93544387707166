export const formatAddress = (value) => {
  if (value == undefined || value == "" || value == null) return "";
  return value.substring(0,4) + "..." + value.substring(value.length -4,);
}

export const combineAddress = (address1, address2) => {
  const addr1 = formatAddressMicroText(address1);
  const addr2 = formatAddressMicroText(address2);
  if (addr1 > addr2) return addr1 + addr2;
  else return addr2 + addr1;
}

export const formatAddressMicro = (value) => {
  return value.substring(0,6) + "..." + value.substring(value.length -6,);
}

export const formatAddressMicroText = (value) => {
  return value.substring(0,6) + value.substring(value.length -6,);
}

export const formatAddressMini = (value) => {
  if (value == "") return "-";
  return value.substring(value.length -6,);
}

export const formatInscriptionId = (value) => {
  if (value == "") return "-";
  return value.substring(0,12) + "..." + value.substring(value.length -12,);
}

export const formatTime = (value) => {
  return value.substring(0, 10) + " " + value.substring(11, 16);
}

export const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);

  // Extract the different parts of the date
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes().toString().padStart(2, '0');
  const amPm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 24h to 12h format

  // Format the date as "Dec 14, 2023, 6:23 PM"
  const formattedDate = `${month} ${day}, ${year}, ${formattedHour}:${minute} ${amPm}`;

  return formattedDate;
}

export const getAsciiSum = (str) => {
  let sum = 0;
  for(let i = 0; i < str.length; i++)
  {
    sum = sum + str.charCodeAt(i);
  }
  return sum;
} 

export const formatUnixTime = (dateStr) => {
  if (dateStr.length > 12) return dateStr.split(' ')[0];
  else {
    const unixTimestamp = parseInt(dateStr);
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = 
      date.getFullYear() + '-' + 
      ('0' + (date.getMonth() + 1)).slice(-2) + '-' + 
      ('0' + date.getDate()).slice(-2)

    return formattedDate; 
  }
}

export const amountFormat = (num) => {
  const formattedNumber = num.toLocaleString('en-US');
  return formattedNumber;
}

