import React, {useState, useEffect} from 'react'
import { emptyBalance, updateBalance } from '../../store/action';
const { io } = require('socket.io-client');
import { useSelector, useDispatch } from 'react-redux';
// import { trac } from '../../util/tap-util';
import { Spinner } from '@material-tailwind/react';
import { v4 as uuidv4 } from 'uuid';
import TokenCard from '../TokenCard';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import TapCard from '../TokenSaleCard/TapCard';
import { getBtcPrice, saveTokenTransferMintData } from '../../util/api';
import { getPaymentUtxos } from '../../util/new-api';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFeeReal, getData, isValidTaprootAddress} from '../../util/inscribe-util';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';
import FeeRateCardNew from '../FeeRateCardNew';
import {
  useLocation
} from "react-router-dom";
import { 
  Input,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Button,
} from '@material-tailwind/react'
import { useWallet, useWallets } from '@wallet-standard/react';
import { ownerAddress, encodedAddressPrefix, padding } from '../../configs/constant';
import toast, { Toaster } from 'react-hot-toast';
import { sleep } from '../../util/api';
import { savePointData } from '../../util/api';

// const tempTapDetailData = {
//   id: "aa1404f881ba8a42c0ef60fc808e08755e475eeda16b41624bd9b61ca8513b9ai0", 
//   amount: 1,
//   ticker: "tapzero"
// }

export default function Tap(props) {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [customFee, setCustomFee] = useState(0);

  const { keyAddress } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const tap = useSelector(state => state.tap);
  const [loading, setLoading] = useState(false);
  const [tapDatas, setTapDatas] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [tapDetailData, setTapDetailData]= useState([]);
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [currentFee, setCurrentFee] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [totalFee, setTotalFee] = useState(0);

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  useEffect(() => {
    if (feeRateMode == "Custom") {
      setCurrentFee(customFee);
      setTotalFee(calculateInscribeFee(customFee)["totalFee"]);
    }
    else {
      setCurrentFee(feeRates[feeRateMode]);
      setTotalFee(calculateInscribeFee(feeRates[feeRateMode])["totalFee"]);
    }
  }, [customFee, feeRateMode, mintAmount])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  useEffect(() => {
    fetchTokenData();
  }, [keyAddress])

  const fetchTokenData = () => {
    setLoading(true);
    setTapDatas([]);
    let tempTapDatas = [];
    let total = 0;

    const trac = io('https://tap-site.trac.network', {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 500,
      randomizationFactor: 0
    });

    trac.connect();

    trac.on('response', async function (msg) {
      if (typeof msg.call_id === 'object') {
        if (msg.call_id.cmd == 'renderBalances_accountTokens') {
          getAccountTokens(msg.call_id.address, 0, 500, {
            cmd: 'renderBalances_getAccountTokens',
            address: msg.call_id.address
          });
        } else if (msg.call_id.cmd == 'renderBalances_getAccountTokens') {
          total = msg.result.length;
          if (total == 0) setLoading(false);
          for (let i = 0; i < msg.result.length; i++) {
            getBalance(msg.call_id.address, msg.result[i], {
              cmd: 'renderBalances_getDeployment',
              address: msg.call_id.address,
              ticker: msg.result[i]
            });
          }
        } else if (msg.call_id.cmd == 'renderBalances_getDeployment') {
          getDeployment(msg.call_id.ticker, {
            cmd: 'renderBalances_getTransferable',
            address: msg.call_id.address,
            ticker: msg.call_id.ticker,
            balance: msg.result
          });
        } else if (msg.call_id.cmd == 'renderBalances_getTransferable') {
          getTransferable(msg.call_id.address, msg.call_id.ticker, {
            cmd: 'renderBalances',
            address: msg.call_id.address,
            ticker: msg.call_id.ticker,
            balance: msg.call_id.balance,
            deployment: msg.result
          });
        } else if (msg.call_id.cmd == 'renderBalances') {
          const transferable = msg.result == null ? BigInt(0) : BigInt(msg.result);
          const balance = BigInt(msg.call_id.balance);
          const available = balance - transferable;

          console.log(
            'address',
            msg.call_id.address,
            'ticker',
            msg.call_id.ticker,
            'transferable',
            formatNumberString(transferable.toString(), msg.call_id.deployment.dec),
            'available',
            formatNumberString(available.toString(), msg.call_id.deployment.dec),
            'balance',
            formatNumberString(balance.toString(), msg.call_id.deployment.dec)
          );

          dispatch(
            updateBalance({
              ticker: msg.call_id.ticker,
              transferableBalance: formatNumberString(transferable.toString(), msg.call_id.deployment.dec),
              availableBalance: formatNumberString(available.toString(), msg.call_id.deployment.dec),
              overallBalance: formatNumberString(balance.toString(), msg.call_id.deployment.dec),
              availableBalanceSafe: formatNumberString(available.toString(), msg.call_id.deployment.dec),
              availableBalanceUnSafe: '0',
              dec: msg.call_id.deployment.dec
            })
          );

          tempTapDatas.push({
            ticker: msg.call_id.ticker,
            transferableBalance: formatNumberString(transferable.toString(), msg.call_id.deployment.dec),
            availableBalance: formatNumberString(available.toString(), msg.call_id.deployment.dec),
            overallBalance: formatNumberString(balance.toString(), msg.call_id.deployment.dec),
            availableBalanceSafe: formatNumberString(available.toString(), msg.call_id.deployment.dec),
            availableBalanceUnSafe: '0',
            dec: msg.call_id.deployment.dec
          });
          if (tempTapDatas.length == total)
          {
            setTapDatas(tempTapDatas);
            setLoading(false);
          }
        }
      }
    });

    const renderBalances = async (address) => {

      dispatch(emptyBalance({}));
      tempTapDatas = [];
  
      trac.emit('get', {
        func: 'accountTokensLength',
        args: [address],
        call_id: { cmd: 'renderBalances_accountTokens', address: address }
      });
    };

    const getAccountTokens = async (address, offset = 0, max = 500, call_id) => {
      trac.emit('get', {
        func: 'accountTokens',
        args: [address, offset, max],
        call_id: call_id === null ? '' : call_id
      });
    };

    const getBalance = async (address, ticker, call_id) => {
      trac.emit('get', {
        func: 'balance',
        args: [address, ticker],
        call_id: call_id === null ? '' : call_id
      });
    };

    const getTransferable = async (address, ticker, call_id) => {
      trac.emit('get', {
        func: 'transferable',
        args: [address, ticker],
        call_id: call_id === null ? '' : call_id
      });
    };

    const getDeployment = async (ticker, call_id) => {
      trac.emit('get', {
        func: 'deployment',
        args: [ticker],
        call_id: call_id === null ? '' : call_id
      });
    };

    const formatNumberString = (string, decimals) => {
      const pos = string.length - decimals;
      if (decimals == 0) {
        // nothing
      } else if (pos > 0) {
        //string = string.substring(0, pos) + '.' + string.substring(pos, string.length);
        string = string.substring(0, pos);
      } else {
        string = '0'; //'0.' + '0'.repeat(decimals - string.length) + string;
      }

      return string;
    };

    renderBalances(keyAddress);
  }

  const calculateInscribeFee = (feeRate) => {
    const text = `{"p":"tap","op":"token-transfer","tick":"${currentData.ticker}","amt":"${mintAmount}"}`;
    let dataSize = text.length;
    let mintCount = 1;
    if (mintCount <= 0 || mintCount == NaN) mintCount = 1;
    let base_size = 160;
    let prefix = 160;
    let txsize = prefix + Math.floor(dataSize / 4);
    let inscriptionLength = mintCount;
    let inscriptionFee = padding ;
    let networkFee = Math.floor(feeRate * txsize * 1.1);
    let royalty = 0;
    let serviceFeeRate = 0.1;
    let serviceFeeLimit = 3000;

    let royaltyFee = royalty * mintCount;
    let serviceFee = Math.floor((royaltyFee + inscriptionFee * mintCount + networkFee * mintCount) * serviceFeeRate);
  
    if (serviceFee < serviceFeeLimit) serviceFee = serviceFeeLimit;
    //let totalFee = inscriptionFee * mintCount + networkFee * mintCount + serviceFee + royaltyFee;
    let totalFee = inscriptionFee * mintCount + networkFee * mintCount + serviceFee;
    return {
      "inscriptionFee": inscriptionFee,
      "networkFee": networkFee,
      "serviceFee": serviceFee,
      "royaltyFee": royaltyFee,
      "totalFee": totalFee
    }
  }

  const handleShowDetail = (data) => {
    if (!location.pathname.includes('/profile')) return;

    setShowDetail(true);
    setCurrentData(data);

    if (parseInt(data.transferableBalance) > 0) {
      setLoading(true);
      let tempTapDatas = [];
      let total = 0;

      const trac = io('https://tap-site.trac.network', {
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionDelayMax: 500,
        randomizationFactor: 0
      });

      trac.connect();

      trac.on('response', async function (msg) {
        if (msg.call_id.cmd == "account_transfer_list") {
          let results = msg.result;
          for(let result of results) {
            if (result.fail == false) {
              tempTapDatas.push({
                ticker: data.ticker,
                amount: formatNumberString(result.amt, data.dec),
                id: result.ins,
                number: result.num,
                block: result.blck,
              })
            }
          }
          getSentList();
        }
        if (msg.call_id.cmd == "account_sent_list") {
          let results = msg.result;
          for(let result of results) {
            if (result.fail == false) {
              let newTempDatas = [];
              for(let tempData of tempTapDatas) {
                if (tempData.id != result.ins) {
                  newTempDatas.push(tempData);
                }
              }
              tempTapDatas = newTempDatas;
            }
          }
          setTapDetailData(tempTapDatas);
          setLoading(false);
        }
      });
      
      trac.emit('get',
      {
          func : 'accountTransferList',
          args : [keyAddress, data.ticker, 0, 500],
          call_id: { cmd: 'account_transfer_list', address: keyAddress }
      });

      const getSentList = async () => {
        trac.emit('get', {
          func: 'accountSentList',
          args: [keyAddress, data.ticker, 0, 500],
          call_id: { cmd: 'account_sent_list', address: keyAddress }
        });
      };

      const formatNumberString = (string, decimals) => {
        const pos = string.length - decimals;
        if (decimals == 0) {
          // nothing
        } else if (pos > 0) {
          //string = string.substring(0, pos) + '.' + string.substring(pos, string.length);
          string = string.substring(0, pos);
        } else {
          string = '0'; //'0.' + '0'.repeat(decimals - string.length) + string;
        }
  
        return string;
      };
    }
    else {
      setTapDetailData([]);
    }
    
  }

  const handleMint = async (data = currentData) => {

    setShowSpinner(true);
    if (parseInt(mintAmount) > parseInt(currentData.availableBalanceSafe)) {
      setShowSpinner(false);
      setShow(false);
      toast.error("Exceed maximum avaiable balance.");
      return;
    }

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    const mimetype = "text/plain;charset=utf-8";
    const text = `{"p":"tap","op":"token-transfer","tick":"${currentData.ticker}","amt":"${mintAmount}"}`;

    const fees = calculateInscribeFee(currentFee);

    const inscriptionFee = fees["inscriptionFee"] + fees["networkFee"];

    let fundingAddress = ownerAddress;
    //let fundingAmount = 0;
    let fundingAmount = fees["serviceFee"];

    for(let i = 0; i< 1; i++)
    {
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    if (fundingAmount > 0) {
      recipientList.push({
        address: fundingAddress,
        amountSats: BigInt(fundingAmount),
      })
    }

    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // get payment utxos

    console.log(recipientList);

    const paymentUtxos = await getPaymentUtxos(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, fundingAmount, wallet.paymentPublicKey, currentFee, 1, wallet.domain)

    let isSuccess = true;

    if (paymentUtxos.status == "fail") {
      toast.error("Insufficinet balance.");
      setShowSpinner(false);
      setShow(false);
      isSuccess = false;
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
            },
            onCancel: () => {
                toast.error('Request canceled.');
                setShowSpinner(false);
                isSuccess = false;
                setShow(false);
            },
          });
  
        }
        else {
          toast.error("Insufficinet balance.");
          setShowSpinner(false);
          setShow(false);
          isSuccess = false;
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
            },
            onCancel: () => {
                toast.error('Request canceled.');
                setShowSpinner(false);
                isSuccess = false;
                setShow(false);
            },
          });
  
        }
        else {
          toast.error("Insufficinet balance.");
          setShowSpinner(false);
          setShow(false);
          isSuccess = false;
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      toast.error("Request canceled.");
      isSuccess = false;
      setShowSpinner(false);
      setShow(false);
      return;
    }
    
    if (isSuccess) {
      // save private key and public key
      await saveTokenTransferMintData({
          key: privkey,
          address: wallet.nostrOrdinalsAddress,
          text: text
      });

      // let params = {
      //   address : wallet.nostrOrdinalsAddress,
      //   point : 1,
      //   reason : "Inscribe Transfer",
      //   deleteFlag : 0
      // }
      // await savePointData(params);

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      for (let i = 0; i < inscriptions.length; i++) {
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
      }
      toast.success("Inscribe successfully done. Please wait.")
    }
    setShowSpinner(false);
    setShow(false);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey) => {
    let _toAddress;
    let _script;
    let toAddress = wallet.nostrOrdinalsAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        include_mempool = false;
        inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {

        JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
    }
    
  }

  const handleInscribeTransfer = async () => {
    
    let response = await getFeeRate(); // buy ordinals
    let value = (sliderValue / 100) * (500 - response["Normal"]) + response["Normal"];
    setCustomFee(Math.floor(value));
    setFeeRates(response);
    setShow(true);
  }

  const handleMintAmount = (e) => {
    setMintAmount(e.target.value);
  }
  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {/* <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
        <TapCard data = {tempTapDetailData} price={price} />
      </div>  */}
      {
        showDetail ? 
          <div className="flex flex-col">
            <div className="mt-3 font-bold text-primary text-[24px] flex flex-row gap-2 items-center">
              <ArrowLeftIcon strokeWidth={3} className="h-5 w-5 cursor-pointer" onClick={() => setShowDetail(false)}/>
              <div >{currentData.ticker} ({currentData.transferableBalance})</div>
            </div>
            <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-5">
              {
                tapDetailData.length > 0 ? 
                  tapDetailData.map((data) => 
                    <TapCard key={data.inscriptionId} data = {data} price={price} type={0}/> 
                  ) : <div>There is no transferable amount.</div>
              }
            </div>
            <div className="mt-5 flex flex-row-reverse w-full ">
              <div className="flex-row gap-2 items-center bg-primary px-4 py-[8px] rounded-md cursor-pointer hover:bg-primary-hover" onClick={() => handleInscribeTransfer()}>
                <span className="font-bold text-white">Inscribe Transfer</span>
              </div>
            </div>
          </div>
          :
          <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
            {
              tapDatas.length > 0 ? 
                tapDatas.map((data) => 
                  data.ticker.toLowerCase().includes('dmt-') ? 
                  <></> :
                  <TokenCard key={uuidv4()} tick = {data.ticker} transferable = {data.transferableBalance} available = {data.availableBalance} balance = {data.overallBalance} dec = {data.dec} handle = {() => handleShowDetail(data)}/> 
                ) : <>No TAP tokens.</>
            }
          </div>
      }
      <Dialog
        open={show}
        size={"sm"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col gap-4 w-full items-center p-3">
            <div className="font-bold text-[32px] text-primary mt-4">
              Inscribe Transfer
            </div>
            <div className="text-[24px] font-bold text-white">
              ${currentData.ticker}
            </div>
            <div className="flex flex-row gap-3">
              <div className="font-bold text-[24px] text-white">Available:</div>
              <div className="font-bold text-[24px] text-white">{currentData.availableBalanceSafe}</div>
            </div>
            <div className="flex flex-row gap-3 md:w-[350px] w-[250px]">
              <Input color="white" label = "Inscribe amount" value = {mintAmount} onChange = {(e) => {handleMintAmount(e)}}/>
            </div>
            <div className="w-full text-white font-bold">
              Network Fee:
            </div>
            <div className="grid grid-cols-3 gap-3 w-full">
              <FeeRateCardNew header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
              <FeeRateCardNew header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              <FeeRateCardNew header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
            </div>
            <div className="text-[24px] font-bold text-white">
              {totalFee / Math.pow(10, 8)}BTC
            </div>
            {
              feeRateMode == "Custom" ? 
              <div className="w-full">
                <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
              </div>
              : 
              <></>
            }
            {
              showSpinner ? 
                <Spinner className="h-12 w-12" color="red"/>:
                <div className="flex flex-row gap-3">
                  <button className="bg-primary hover:bg-primary-hover text-white rounded-md px-10 font-bold py-2" onClick={handleMint}>Inscribe</button>
                  <button className="bg-[#212121] text-white rounded-md px-10 font-bold py-2"  onClick={() => setShow(false)}>Return</button>
                </div>
            }
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
